@import "../../styles/variables";

.navHeader {
  position: relative;
  padding: 8px 16px;
}

.mLogo {
  width: 120px;
  margin-top: 4px;
}

.crossIcon {
  position: absolute;
  top: 12px;
  right: 16px;
  z-index: 1;
}

.navLinks {
  padding: 0 24px 16px;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  .navLink {
    display: block;
    font-size: 1.42rem;
    color: #333;
    font-weight: 700;
    padding-block: 8px;
  }

  button {
    padding-inline: 0;
  }
}
