@import "../../styles/variables";

.cityList {
  padding-block-start: 24px;
  border-top: 1px solid map-deep-get($colors, "grays", 200);

  h4 {
    margin-block-end: 16px;
  }
}
