@import "../../styles/variables";

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  padding: .72em .75em;
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: 700;
  border: 1px solid map-deep-get($colors, "brand-colors", "primary");

  > svg {
    flex: none;
    fill: currentColor;
    margin-right: 4px;
  }

  &:hover {
    background-color: #f5f5f5;
  }


  &[disabled] {
    opacity: .4;
    pointer-events: none;
  }
}

.primaryBtn,
.primaryGhostBtn {
  color: #fff;
}

.primaryBtn {
  background-color: map-deep-get($colors, "brand-colors", "primary");
  border: 1px solid map-deep-get($colors, "brand-colors", "primary");

  &:hover {
    background-color: shade(#ff8700, 15%);
    border-color: shade(#ff8700, 15%);
  }
}

.primaryGhostBtn {
  border: 1px solid currentColor;
  background-color: transparent;
  color: map-deep-get($colors, "brand-colors", "primary");

  &:hover {
    background-color: #f5f5f5;
  }
}

.secondaryBtn {
  background-color: map-deep-get($colors, "brand-colors", "secondary");
  border: 1px solid map-deep-get($colors, "brand-colors", "secondary");
  color: #fff;

  &:hover {
    background-color: shade(map-deep-get($colors, "brand-colors", "secondary"), 15%);
    border-color: shade(map-deep-get($colors, "brand-colors", "secondary"), 15%);
  }
}

.secondaryGhostBtn {
  border: 1px solid currentColor;
  background-color: transparent;
  color: map-deep-get($colors, "brand-colors", "secondary");

  &:hover {
    background-color: #f5f5f5;
  }
}

.btnLink {
  border-color: transparent;
  color: map-deep-get($colors, "brand-colors", "secondary");
}

.sm {
  font-size: .875rem;
  padding: .32em .5em;
}

.lg {
  font-size: 1.25rem;
  padding: .7em 1em;
}

.btnBlock {
  width: 100%;
}
