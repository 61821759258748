@import "../../styles/variables";

.banner {
  background-image: url("/lamudi-low-resolution-01.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 320px;
  margin-block-end: 32px;
  padding-block-start: 8px;
  position: relative;
  justify-content: space-between;

  @media (max-width: $screen-portrait-max) {
    min-height: 210px;
  }

  &::before {
    background-image: linear-gradient(to bottom,rgba(0, 0, 0, .4) 50%, rgba(0, 0, 0, .16) 100%);
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  > * {
    position: relative;
  }

  .mainHeading {
    color: #fff;
  }
}

.bannerLessContainer {
  [class^="navbar_mainNav"] {
    position: relative;
    top: 3px;
  }
}

.bannerContainer {
  [class^="logo"] {
    position: relative;
    top: -2px;

    @media (max-width: $screen-portrait-max) {
      top: 2px;
    }
  }
}
