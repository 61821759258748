@import "../../styles/variables";

.sheet {
  background-color: #fff;
  height: 100%;
  position: fixed;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.24s ease, transform 0.3s ease, visibility 0.3s;

  &.active {
    visibility: visible;
    opacity: 1;
  }
}

.horizontalSheet {
  width: 80%;
  top: 0;
}

.sheetRight {
  transform: translateX(100%);
  right: 0;

  &.active {
    transform: translateX(0%);
  }
}

.sheetLeft {
  transform: translateX(-100%);
  left: 0;

  &.active {
    transform: translateX(0%);
  }
}

.verticalSheet {
  inset: 0;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.15);
  padding: 16px;
  max-height: 100%;
  overflow-y: auto;
}

.sheetTop {
  transform: translateY(-100%);
  right: 0;

  &.active {
    transform: translateY(0%);
  }
}

.sheetBottom {
  transform: translateY(100%);

  &.active {
    transform: translateY(0%);
  }
}
