@import "../../styles/variables";

.settingIcon {
  width: 80px;
  height: 80px;
}

.addProperty {
  text-align: center;
}
