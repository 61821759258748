@import "../../styles/variables";

.h1,
.h2,
.h3,
.h4 {
  line-height: 1.3;

  @media (max-width: $screen-xs-max) {
    line-height: 1.2;
  }
}

.h1 {
  font-size: 2.441rem;
  margin-block-end: 2rem;

  @media (max-width: $screen-xs-max) {
    font-size: 1.953rem;
    margin-block-end: 1.5rem;
  }
}

.h2 {
  font-size: 1.953rem;
  margin-block-end: 1.5rem;

  @media (max-width: $screen-xs-max) {
    font-size: 1.563rem;
    margin-block-end: .8rem;
  }
}

.h3 {
  font-size: 1.563rem;
  line-height: 1.3;
  margin-block-end: .8rem;

  @media (max-width: $screen-xs-max) {
    font-size: 1.25rem;
    margin-block-end: .5rem;
  }
}

.h4 {
  font-size: 1.25rem;
  margin-block-end: .5rem;

  @media (max-width: $screen-xs-max) {
    font-size: 1rem;
  }
}

.count {
  color: #a7a7a7;
  font-size: 1.25rem;
}
